import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";
const TOKEN = '5908429213:AAE2B7pxvOmAD0_Jeb44x9_z_rjeduBWP0E';
const CHAT_ID = '-1001664035527';
export const PostCalculate = createAsyncThunk("calculate/post", async (body) => {
  const info = `Имя : ${body.calculate_name} %0AНомер телефона: ${body.phone_number} %0AТип помещения: ${body.room_type} %0AТип жилья: ${body.house_type} %0AПлощадь: ${body.room_area} %0AКомментарий: ${body.coment}`
  await fetch(`https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=${CHAT_ID}&text=${info}&parse_mode=html`)
   await axios.post(`${API_URL}/calculate`, body).then((res) => res); 
});

const CalculateSlice = createSlice({
  name: "calculate",
  initialState: {
    postCalculate: {
      Error: false,
      Success: false,
      Loading: false,
    },
  },
  extraReducers: {
    [PostCalculate.pending]: (state, action) => {
      state.postCalculate.Loading = true;
    },
    [PostCalculate.fulfilled]: (state, action) => {
      state.postCalculate.Error = false;
      state.postCalculate.Success = true;
      state.postCalculate.Loading = false;
    },
    [PostCalculate.rejected]: (state, action) => {
      state.postCalculate.Error = true;
      state.postCalculate.Success = false;
      state.postCalculate.Loading = false;
    },
  },
});

export const {} = CalculateSlice.actions
export default CalculateSlice.reducer