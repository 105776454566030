import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import CommonBtn from "../../common/CommonBtn";
import ModalCommon from "../../common/Modal/Modal";
import { Wrapper, Contact } from "./styled-index";
import CommonInput from "../../common/CommonInput";
import { PostContact } from "../../../redux/contact";
import { useDispatch, useSelector } from "react-redux";
import { Success } from "../../HomeComponent/Hero/styled-index";
import { useState } from "react";
import SuccessIcon from "../../../assets/image/Home/icon-success.png"
import { useNavigate } from "react-router-dom";

function FooterContact() {
  const { t, i18n } = useTranslation();
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => setOpen(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const handleClose = () => setOpen(false);
  const Name = useRef();
  const Number = useRef();
  const navigate = useNavigate();
  const addContact = useSelector((state) => state.contact);
  const dispatch = useDispatch();
  const HendelSubmit = async (e) => {
    e.preventDefault();
    console.log("okey");
    const contact_name = Name.current.value;
    const phone_number = Number.current.value;
    dispatch(PostContact({ contact_name, phone_number }));
    await window.localStorage.setItem("success" , "okey")
     navigate("/success")
     window.location.reload();
   handleClose()
  };
  return (
    <>
        <Wrapper>
      <h2>{t("Footer.0")}</h2>
      <p>{t("Footer.9")}</p>
      <CommonBtn
        onClick={handleOpen}
        style={{
          width: "364px",
          fontSize: "18px",
          fontWeight: "500",
          marginBottom: "86px",
        }}
      >
        {t("Footer.1")}
      </CommonBtn>
      <ModalCommon handleClose={handleClose} open={open}>
        <Contact onSubmit={HendelSubmit}>
          <h3>{t("ContactModal.0")}</h3>
          <h4>{t("ContactModal.1")}</h4>
          <label htmlFor="name">{t("ContactModal.2")}</label>
          <input placeholder={t("ContactModal.3")} required ref={Name} />
          <label htmlFor="number">{t("ContactModal.4")}</label>
          <input placeholder={t("+99 8")} type="number" required ref={Number} />
          <CommonBtn
            type="submit"
            style={{ marginTop: "54px", width: "324px" }}
          >
            {t("Footer.1")}
          </CommonBtn>
        </Contact>
      </ModalCommon>
    </Wrapper>
          <ModalCommon open={open2} handleClose={handleClose2}>
          <Success>
          <img src={SuccessIcon} width={71} height={71} alt="" />
          <p>{t("Hero.4")}</p>
          <button onClick={handleClose2}>Ok</button>
          </Success>
        </ModalCommon>
    </>
  );
}

export default FooterContact;
